import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from 'libs/shared/src/lib/services/auth/auth-business/auth.guard';
import { NoAuthGuard } from 'libs/shared/src/lib/services/auth/auth-business/no-auth.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'external',
  },
  {
    path: 'external',
    canActivate: [NoAuthGuard],
    loadChildren: () => import('./external/external.module').then((m) => m.ExternalModule),
    data: {
      platform: 'SYNAPSES',
    },
  },
  {
    path: 'internal',
    canActivate: [AuthGuard],
    loadChildren: () => import('./internal/internal.module').then((m) => m.InternalModule),
    data: {
      breadcrumb: 'Home',
      platform: 'SYNAPSES',
    },
  },
  {
    path: '**',
    redirectTo: 'internal',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
