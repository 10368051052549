import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { UsersService } from '../../users/users.service';
import { StateManagementService } from '../../../state-management/state-management.service';
import { UserModel } from '../../../models/users/users.model';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private $user: UsersService, private router: Router, private $notification: StateManagementService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const platform = next.data['platform'] ? next.data['platform'] : 'B2B';

    return this.$notification.users.pipe(
      take(1),
      mergeMap((user) => {
        if (user) {
          return of(user);
        } else {
          return this.$user.getCurrentUser(platform);
        }
      }),
      map((res: any) => {
        const accessToken = sessionStorage.getItem('accessToken');
        const user = res?.id ? res : res?.data?.currentUser;
        const isOnboardingInternal = this.$user.isOnboardingStepsInternal(user);
        const statusAprove: string[] = ['IN_PROGRESS', 'ACTIVE'];
        let isSellerActive: boolean = false;

        if (user?.sellers && user?.sellers?.length > 0) {
          isSellerActive = statusAprove.includes(user?.sellers[0]?.status);
        }

        if (user?.id && accessToken && (isOnboardingInternal || isSellerActive || platform !== 'B2B')) {
          this.$notification.setUser(user);
          return true;
        } else {
          this.$user.trackOnboardingSteps(user);
          return false;
        }
      })
    );
  }
}
