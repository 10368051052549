import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { UsersService } from '../../users/users.service';
import { StateManagementService } from '../../../state-management/state-management.service';
import { UserModel } from '../../../models/users/users.model';
@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard implements CanActivate {
  constructor(private $user: UsersService, private router: Router, private $notification: StateManagementService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const platform = next.data['platform'] ? next.data['platform'] : 'B2B';

    const accessToken = sessionStorage.getItem('accessToken');
    if (accessToken) {
      return this.$notification.users.pipe(
        take(1),
        mergeMap((user) => {
          if (user) {
            return of(user);
          } else {
            return this.$user.getCurrentUser(platform);
          }
        }),
        map((res: any) => {
          const user: UserModel = res?.id ? res : res?.data?.currentUser;

          if (user && platform === 'B2B') {
            this.$notification.setUser(JSON.parse(JSON.stringify(user)));
          }

          const isOnboardingInternal = platform === 'B2B' ? this.$user.isOnboardingStepsInternal(user) : true;

          if (user?.id && isOnboardingInternal) {
            this.router.navigate(['/internal']);
            return false;
          } else {
            return true;
          }
        })
      );
    } else {
      return true;
    }
  }
}
