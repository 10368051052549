import { Component, OnInit } from '@angular/core';
import { StatusAlertModel, AlertType, AlertDuration } from 'libs/shared/src/lib/models/status/status-alert.model';
import { StateManagementService } from 'libs/shared/src/lib/state-management/state-management.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  public alertInfo: StatusAlertModel = new StatusAlertModel();
  public alertType: AlertType = new AlertType();
  public alertDuration: AlertDuration = new AlertDuration();

  constructor(private $notification: StateManagementService) {}

  public ngOnInit(): void {
    this.updateAlertStatus();
  }

  public updateAlertStatus(): void {
    this.$notification.sucessAlerts.subscribe((sucessAlerts) => {
      if (sucessAlerts) {
        this.alertInfo = sucessAlerts;
      }
    });

    this.$notification.errorAlerts.subscribe((errorAlerts) => {
      this.alertInfo = errorAlerts;
    });
  }
}
